// src/PositionPerformance.tsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { message, Card, Tooltip, Checkbox } from "antd";
import { getCookie } from "./utils";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartTooltip,
    Legend,
} from "recharts";

// ---------- Types ----------

interface TradingPosition {
    id: number;
    sym_id: number;
    quantity: number;
    enter_datetime: string;
    enter_token_value: number;
    enter_fee: number;
    enter_total_USDC: number;
    exit_percent: number;
    exit_stop_loss: number;
    token_name: string;
    current_value: number;
    floor: number;
    low: number;
    med_low: number;
    med_high: number;
    high: number;
    updated_date: string;
}

interface TokenData {
    sym_id: number;
    token_name: string;
    current_value: number;
    floor: number;
    low: number;
    med_low: number;
    med_high: number;
    high: number;
    updated_date: string;
    sum_trend_1h: number;
    sum_trend_2h: number;
    sum_trend_4h: number;
    sum_trend_8h: number;
    sum_trend_12h: number;
    sum_trend_24h: number;
    sum_trend_1d: number;
    sum_trend_2d: number;
    sum_trend_3d: number;
    sum_trend_5d: number;
    sum_trend_7d: number;
    sum_trend_14d: number;
    sum_trend_30d: number;
}

// ---------- Utility Functions ----------

// A small helper for numeric formatting
function formatNumber(value: number): string {
    const absVal = Math.abs(value);
    if (absVal >= 0.01) {
        return value.toFixed(2);
    } else if (absVal >= 0.0001) {
        return value.toFixed(4);
    } else {
        return value.toFixed(6);
    }
}

// Render the quadrant-based bar from floor -> high (with a black marker)
function renderValueBar(pos: TradingPosition) {
    const { floor, low, med_low, med_high, high, current_value } = pos;

    if (high <= floor) {
        return <div>Value bar unavailable</div>;
    }

    const totalRange = high - floor;
    const segLow = ((low - floor) / totalRange) * 100;
    const segMedLow = ((med_low - floor) / totalRange) * 100;
    const segMedHigh = ((med_high - floor) / totalRange) * 100;
    const currentRatio = ((current_value - floor) / totalRange) * 100;

    return (
        <div style={{ position: "relative", margin: "8px 0", height: 16 }}>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 16,
                    display: "flex",
                }}
            >
                <div
                    style={{
                        width: `${segLow}%`,
                        backgroundColor: "#cce5ff", // Light-blue (floor -> low)
                    }}
                />
                <div
                    style={{
                        width: `${segMedLow - segLow}%`,
                        backgroundColor: "#b2f0b2", // Light-green (low -> med_low)
                    }}
                />
                <div
                    style={{
                        width: `${segMedHigh - segMedLow}%`,
                        backgroundColor: "#fff3cd", // Light-yellow (med_low -> med_high)
                    }}
                />
                <div
                    style={{
                        flex: 1,
                        backgroundColor: "#f8d7da", // Light-pink (med_high -> high)
                    }}
                />
            </div>

            {/* Marker for the current_value (with tooltip) */}
            <Tooltip title={`Current Value: ${formatNumber(current_value)}`} placement="top">
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: `${Math.min(Math.max(currentRatio, 0), 100)}%`,
                        transform: "translateX(-50%)",
                        height: 16,
                        width: 2,
                        backgroundColor: "black",
                        cursor: "pointer",
                    }}
                />
            </Tooltip>
        </div>
    );
}

// Render the horizontal Gain/Loss bar from -stop_loss% to +exit_percent%
function renderGainLossBar(pos: TradingPosition) {
    const currentTotal = pos.current_value * pos.quantity;
    const gainLoss = currentTotal - pos.enter_total_USDC;
    const gainLossPercent = (gainLoss / pos.enter_total_USDC) * 100;

    const scaleMin = -pos.exit_stop_loss;
    const scaleMax = pos.exit_percent;

    if (scaleMax <= scaleMin) {
        return <div>Gain/loss bar unavailable</div>;
    }

    const totalScale = scaleMax - scaleMin;
    const markerRatio = ((gainLossPercent - scaleMin) / totalScale) * 100;

    const zeroRatio = ((0 - scaleMin) / totalScale) * 100;
    const leftRedWidth = Math.max(Math.min(zeroRatio, 100), 0);
    const rightGreenWidth = 100 - leftRedWidth;

    return (
        <div style={{ marginTop: 8 }}>
            <p>
                <strong>Gain/Loss Range:</strong>{" "}
                {formatNumber(scaleMin)}% (Stop) → 0% → {formatNumber(scaleMax)}% (Exit)
            </p>

            <div style={{ position: "relative", margin: "8px 0", height: 16 }}>
                {/* Negative (red) part and positive (green) part */}
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: 16,
                        width: "100%",
                        display: "flex",
                    }}
                >
                    <div
                        style={{
                            width: `${leftRedWidth}%`,
                            backgroundColor: "#f8d7da",
                        }}
                    />
                    <div
                        style={{
                            width: `${rightGreenWidth}%`,
                            backgroundColor: "#b2f0b2",
                        }}
                    />
                </div>

                {/* Marker for the current gainLossPercent (with tooltip) */}
                <Tooltip
                    title={`Current Gain/Loss: ${
                        gainLossPercent >= 0 ? "+" : ""
                    }${formatNumber(gainLossPercent)}%`}
                    placement="top"
                >
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: `${Math.min(Math.max(markerRatio, 0), 100)}%`,
                            transform: "translateX(-50%)",
                            height: 16,
                            width: 2,
                            backgroundColor: "black",
                            cursor: "pointer",
                        }}
                    />
                </Tooltip>
            </div>
        </div>
    );
}

// ---------- Child Component: GainLossOverTimeChart ----------

const GainLossOverTimeChart: React.FC<{
    pos: TradingPosition;
    historyData: TokenData[];
}> = ({ pos, historyData }) => {
    const { quantity, enter_total_USDC, exit_percent, exit_stop_loss, token_name } = pos;

    // First: define all hooks at top-level (unconditionally).
    // We'll keep them even if there's no data, but we'll skip rendering below.
    const trendKeys = [
        "sum_trend_1h",
        "sum_trend_2h",
        "sum_trend_4h",
        "sum_trend_8h",
        "sum_trend_12h",
        "sum_trend_24h",
        "sum_trend_1d",
        "sum_trend_2d",
        "sum_trend_3d",
        "sum_trend_5d",
        "sum_trend_7d",
        "sum_trend_14d",
        "sum_trend_30d",
    ];

    // By default, we want all checkboxes OFF
    const [enabledTrends, setEnabledTrends] = useState<Record<string, boolean>>(() =>
        trendKeys.reduce((acc, k) => {
            acc[k] = false;
            return acc;
        }, {} as Record<string, boolean>)
    );

    // Now we can do any conditionals/returns AFTER the hook calls.
    if (!historyData.length) {
        return <p style={{ marginTop: 16 }}>No historical data available for token {token_name}.</p>;
    }

    // Filter from position entry date forward
    const entryDate = new Date(pos.enter_datetime);
    const filtered = historyData.filter((h) => {
        const hDate = new Date(h.updated_date);
        return hDate >= entryDate;
    });

    if (!filtered.length) {
        return (
            <p style={{ marginTop: 16 }}>
                No post-entry historical data found for token {token_name}.
            </p>
        );
    }

    // Build chart data
    const chartData = filtered.map((h) => {
        const hDate = new Date(h.updated_date);
        const hypotheticalValue = h.current_value * quantity;
        const gainLoss = hypotheticalValue - enter_total_USDC;
        const gainLossPercent = (gainLoss / enter_total_USDC) * 100;

        return {
            time: hDate.toISOString(),
            gl: gainLossPercent,
            exitLine: exit_percent,
            stopLine: -exit_stop_loss,

            sum_trend_1h: h.sum_trend_1h,
            sum_trend_2h: h.sum_trend_2h,
            sum_trend_4h: h.sum_trend_4h,
            sum_trend_8h: h.sum_trend_8h,
            sum_trend_12h: h.sum_trend_12h,
            sum_trend_24h: h.sum_trend_24h,
            sum_trend_1d: h.sum_trend_1d,
            sum_trend_2d: h.sum_trend_2d,
            sum_trend_3d: h.sum_trend_3d,
            sum_trend_5d: h.sum_trend_5d,
            sum_trend_7d: h.sum_trend_7d,
            sum_trend_14d: h.sum_trend_14d,
            sum_trend_30d: h.sum_trend_30d,
        };
    });

    const handleCheckboxChange = (key: string, checked: boolean) => {
        setEnabledTrends((prev) => ({ ...prev, [key]: checked }));
    };

    const trendColors: Record<string, string> = {
        sum_trend_1h: "#ff7f50",  // coral
        sum_trend_2h: "#87ceeb",  // skyblue
        sum_trend_4h: "#ffa500",  // orange
        sum_trend_8h: "#a020f0",  // purple
        sum_trend_12h: "#ff1493", // deeppink
        sum_trend_24h: "#32cd32", // limegreen
        sum_trend_1d: "#b8860b",  // darkgoldenrod
        sum_trend_2d: "#40e0d0",  // turquoise
        sum_trend_3d: "#9370db",  // mediumpurple
        sum_trend_5d: "#ba55d3",  // mediumorchid
        sum_trend_7d: "#ff6347",  // tomato
        sum_trend_14d: "#1e90ff", // dodgerblue
        sum_trend_30d: "#f0e68c", // khaki
    };

    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: 600,
                    marginTop: 16,
                    backgroundColor: "#222",
                    padding: "16px",
                    borderRadius: "8px",
                }}
            >
                <ResponsiveContainer>
                    <LineChart data={chartData} margin={{ top: 40, right: 40, bottom: 40, left: 20 }}>
                        <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                        <XAxis
                            dataKey="time"
                            stroke="#ccc"
                            tickFormatter={(val: string) => {
                                const d = new Date(val);
                                return d.toLocaleDateString();
                            }}
                        />
                        <YAxis stroke="#ccc" />
                        <RechartTooltip
                            contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                            labelStyle={{ color: "#fff" }}
                            itemStyle={{ color: "#fff" }}
                            formatter={(value: number, name: string) => {
                                if (["gl", "exitLine", "stopLine"].includes(name)) {
                                    return `${formatNumber(value)}%`;
                                }
                                return formatNumber(value);
                            }}
                        />
                        <Legend wrapperStyle={{ color: "#fff" }} />

                        {/* Gain/Loss line */}
                        <Line
                            type="monotone"
                            dataKey="gl"
                            stroke="#8884d8"
                            strokeWidth={2}
                            dot={false}
                            name="Gain/Loss %"
                        />
                        {/* Exit line */}
                        <Line
                            type="monotone"
                            dataKey="exitLine"
                            stroke="#4fd156"
                            strokeDasharray="3 3"
                            dot={false}
                            name="Exit Target %"
                        />
                        {/* Stop line */}
                        <Line
                            type="monotone"
                            dataKey="stopLine"
                            stroke="#d14f4f"
                            strokeDasharray="3 3"
                            dot={false}
                            name="Stop Loss %"
                        />

                        {/* sum_trend lines only if checkbox is enabled */}
                        {trendKeys.map((k) =>
                            enabledTrends[k] ? (
                                <Line
                                    key={k}
                                    type="monotone"
                                    dataKey={k}
                                    stroke={trendColors[k] || "#aaaaaa"}
                                    dot={false}
                                    name={k}
                                />
                            ) : null
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </div>

            {/* Checkboxes to toggle each sum_trend line */}
            <div
                style={{
                    marginTop: 16,
                    backgroundColor: "#222",
                    padding: "8px",
                    borderRadius: "4px",
                }}
            >
                <p style={{ color: "#fff" }}>
                    <strong>Show Moving Average Lines:</strong>
                </p>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
                    {trendKeys.map((k) => (
                        <Checkbox
                            key={k}
                            checked={enabledTrends[k]}
                            onChange={(e) => handleCheckboxChange(k, e.target.checked)}
                            style={{ color: "#fff" }}
                        >
                            {k}
                        </Checkbox>
                    ))}
                </div>
            </div>
        </>
    );
};

// ---------- Main Component ----------

const PositionPerformance: React.FC = () => {
    const { planId } = useParams();
    const [positions, setPositions] = useState<TradingPosition[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [tokenHistories, setTokenHistories] = useState<Record<number, TokenData[]>>({});

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;

    useEffect(() => {
        if (!planId) {
            setLoading(false);
            return;
        }

        const jwtToken = getCookie("CF_Authorization");
        if (!jwtToken) {
            console.error("Auth Error: JWT token not found");
            message.error("You must be logged in.");
            setLoading(false);
            return;
        }

        // Fetch open positions for the plan
        fetch(`${backendUrl}/api/open-positions/${planId}`, {
            headers: {
                "Content-Type": "application/json",
                "cf-access-jwt-assertion": jwtToken,
            },
        })
            .then((resp) => {
                if (!resp.ok) throw new Error("Failed to fetch open positions");
                return resp.json();
            })
            .then((data: TradingPosition[]) => {
                setPositions(data);

                // Once we have positions, fetch token history for each unique sym_id
                const uniqueSymIds = Array.from(new Set(data.map((p) => p.sym_id)));
                return Promise.all(
                    uniqueSymIds.map(async (symId) => {
                        const res = await fetch(`${backendUrl}/api/token-history/${symId}`, {
                            headers: {
                                "Content-Type": "application/json",
                                "cf-access-jwt-assertion": jwtToken,
                            },
                        });
                        if (!res.ok) {
                            throw new Error(`Failed to fetch token history for sym_id=${symId}`);
                        }
                        const hist: TokenData[] = await res.json();
                        return { symId, hist };
                    })
                );
            })
            .then((allHistories) => {
                const newHistState: Record<number, TokenData[]> = {};
                allHistories.forEach(({ symId, hist }) => {
                    newHistState[symId] = hist;
                });
                setTokenHistories(newHistState);
            })
            .catch((err) => {
                console.error("Error fetching open positions or token history:", err);
                message.error("Could not load open positions or history. Please try again.");
            })
            .finally(() => setLoading(false));
    }, [planId, backendUrl]);

    // A helper for your original multi-line chart
    function renderHistoryChart(pos: TradingPosition) {
        const symId = pos.sym_id;
        const historyData = tokenHistories[symId] || [];
        if (!historyData.length) {
            return <p>No historical data available for token {pos.token_name}.</p>;
        }

        const chartData = historyData.map((h) => ({
            time: h.updated_date,
            current: h.current_value,
            floor: h.floor,
            low: h.low,
            med_low: h.med_low,
            med_high: h.med_high,
            high: h.high,
        }));

        return (
            <div
                style={{
                    width: "100%",
                    height: 600,
                    marginTop: 16,
                    backgroundColor: "#222",
                    padding: "16px",
                    borderRadius: "8px",
                }}
            >
                <ResponsiveContainer>
                    <LineChart data={chartData} margin={{ top: 40, right: 40, bottom: 40, left: 20 }}>
                        <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                        <XAxis
                            dataKey="time"
                            stroke="#ccc"
                            tickFormatter={(val: string) => {
                                const d = new Date(val);
                                return d.toLocaleDateString();
                            }}
                        />
                        <YAxis stroke="#ccc" />
                        <RechartTooltip
                            contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                            labelStyle={{ color: "#fff" }}
                            itemStyle={{ color: "#fff" }}
                        />
                        <Legend wrapperStyle={{ color: "#fff" }} />

                        <Line
                            type="monotone"
                            dataKey="current"
                            stroke="#8884d8"
                            strokeWidth={2}
                            dot={false}
                            name="Current"
                        />
                        <Line
                            type="monotone"
                            dataKey="floor"
                            stroke="#999"
                            strokeDasharray="3 3"
                            dot={false}
                            name="Floor"
                        />
                        <Line
                            type="monotone"
                            dataKey="low"
                            stroke="#4fd156"
                            dot={false}
                            name="Low"
                        />
                        <Line
                            type="monotone"
                            dataKey="med_low"
                            stroke="#d1cd4f"
                            dot={false}
                            name="Med-Low"
                        />
                        <Line
                            type="monotone"
                            dataKey="med_high"
                            stroke="#d1974f"
                            dot={false}
                            name="Med-High"
                        />
                        <Line
                            type="monotone"
                            dataKey="high"
                            stroke="#d14f4f"
                            dot={false}
                            name="High"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }

    if (loading) {
        return <p>Loading open positions...</p>;
    }

    if (!positions.length) {
        return (
            <div>
                <h1>Position Performance</h1>
                <p>No open positions found for trading plan {planId}.</p>
            </div>
        );
    }

    return (
        <div>
            <h1>Position Performance for Trading Plan {planId}</h1>

            {positions.map((pos) => {
                const currentTotal = pos.current_value * pos.quantity;
                const gainLoss = currentTotal - pos.enter_total_USDC;
                const gainLossPercent = (gainLoss / pos.enter_total_USDC) * 100;

                const distanceToExit = pos.exit_percent - gainLossPercent;
                const distanceToStop = -pos.exit_stop_loss - gainLossPercent;

                return (
                    <Card
                        key={pos.id}
                        style={{ marginBottom: 16 }}
                        title={`Token: ${pos.token_name} (Position ID: ${pos.id})`}
                    >
                        <p>
                            <strong>Quantity:</strong> {pos.quantity}
                        </p>
                        <p>
                            <strong>Entered On:</strong>{" "}
                            {new Date(pos.enter_datetime).toLocaleString()}
                        </p>
                        <p>
                            <strong>Entry Price (token):</strong> {formatNumber(pos.enter_token_value)}
                        </p>
                        <p>
                            <strong>Entry Total (USDC):</strong> {formatNumber(pos.enter_total_USDC)}
                        </p>
                        <p>
                            <strong>Exit Target (%):</strong> {formatNumber(pos.exit_percent)}%
                        </p>
                        <p>
                            <strong>Stop Loss (%):</strong> {formatNumber(pos.exit_stop_loss)}%
                        </p>
                        <p>
                            <strong>Current Token Value:</strong> {formatNumber(pos.current_value)}
                        </p>
                        <p>
                            <strong>Updated:</strong>{" "}
                            {new Date(pos.updated_date).toLocaleString()}
                        </p>

                        <p>
                            <strong>Current Position Value (USDC):</strong> {formatNumber(currentTotal)}
                        </p>
                        <p>
                            <strong>Gain/Loss (USDC):</strong>{" "}
                            {gainLoss >= 0 ? "+" : ""}
                            {formatNumber(gainLoss)}
                        </p>
                        <p>
                            <strong>Gain/Loss (%):</strong> {gainLossPercent >= 0 ? "+" : ""}
                            {formatNumber(gainLossPercent)}%
                        </p>
                        <p>
                            <strong>Distance to Exit Target:</strong> {formatNumber(distanceToExit)}%{" "}
                            {distanceToExit > 0 ? "(below exit goal)" : "(above exit goal)"}
                        </p>
                        <p>
                            <strong>Distance to Stop Loss:</strong>{" "}
                            {formatNumber(Math.abs(distanceToStop))}%{" "}
                            {distanceToStop < 0 ? "(closer to stop?)" : "(away from stop?)"}
                        </p>

                        {/* Quadrant-based bar (floor -> high) */}
                        <p>
                            <strong>Value Range:</strong> Floor {formatNumber(pos.floor)} | Low{" "}
                            {formatNumber(pos.low)} | Med-Low {formatNumber(pos.med_low)} | Med-High{" "}
                            {formatNumber(pos.med_high)} | High {formatNumber(pos.high)}
                        </p>

                        {/* Render Bars */}
                        {renderValueBar(pos)}
                        {renderGainLossBar(pos)}

                        {/* Original multi-line chart */}
                        {renderHistoryChart(pos)}

                        {/* Gain/Loss Over Time with sum_trend lines */}
                        <GainLossOverTimeChart
                            pos={pos}
                            historyData={tokenHistories[pos.sym_id] || []}
                        />
                    </Card>
                );
            })}
        </div>
    );
};

export default PositionPerformance;

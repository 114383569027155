// src/TokenZones.tsx

import React, { useEffect, useState } from "react";
import { message, Card, Select, Button, Modal, Input } from "antd";
import { getCookie } from "./utils";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartTooltip,
    Legend,
} from "recharts";

const { Option } = Select;

interface TokenOption {
    id: number;
    name: string;
    mint: string;
}

interface TokenZoneData {
    id: number;
    token_name: string;
    current_value: number;
    floor: number;
    low: number;
    med_low: number;
    med_high: number;
    high: number;
    updated_date: string;
}

function formatNumber(value: number): string {
    const absVal = Math.abs(value);
    if (absVal >= 0.01) {
        return value.toFixed(2);
    } else if (absVal >= 0.0001) {
        return value.toFixed(4);
    } else {
        return value.toFixed(6);
    }
}

function renderZonesChart(historyData: TokenZoneData[], tokenName: string) {
    if (!historyData.length) {
        return <p>No history available for {tokenName}.</p>;
    }

    const chartData = historyData.map((d) => ({
        time: d.updated_date,
        current: d.current_value,
        floor: d.floor,
        low: d.low,
        med_low: d.med_low,
        med_high: d.med_high,
        high: d.high,
    }));

    return (
        <div
            style={{
                width: "100%",
                height: 600,
                marginTop: 16,
                backgroundColor: "#222",
                padding: "16px",
                borderRadius: "8px",
            }}
        >
            <ResponsiveContainer>
                <LineChart data={chartData} margin={{ top: 40, right: 40, bottom: 40, left: 20 }}>
                    <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                    <XAxis
                        dataKey="time"
                        stroke="#ccc"
                        tickFormatter={(val: string) => {
                            const d = new Date(val);
                            return d.toLocaleDateString();
                        }}
                    />
                    <YAxis stroke="#ccc" />
                    <RechartTooltip
                        contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                        labelStyle={{ color: "#fff" }}
                        itemStyle={{ color: "#fff" }}
                    />
                    <Legend wrapperStyle={{ color: "#fff" }} />

                    <Line
                        type="monotone"
                        dataKey="current"
                        stroke="#8884d8"
                        strokeWidth={2}
                        dot={false}
                        name="Current"
                    />
                    <Line
                        type="monotone"
                        dataKey="floor"
                        stroke="#999"
                        strokeDasharray="3 3"
                        dot={false}
                        name="Floor"
                    />
                    <Line
                        type="monotone"
                        dataKey="low"
                        stroke="#4fd156"
                        dot={false}
                        name="Low"
                    />
                    <Line
                        type="monotone"
                        dataKey="med_low"
                        stroke="#d1cd4f"
                        dot={false}
                        name="Med-Low"
                    />
                    <Line
                        type="monotone"
                        dataKey="med_high"
                        stroke="#d1974f"
                        dot={false}
                        name="Med-High"
                    />
                    <Line
                        type="monotone"
                        dataKey="high"
                        stroke="#d14f4f"
                        dot={false}
                        name="High"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

const TokenZones: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [tokens, setTokens] = useState<TokenOption[]>([]);
    const [histories, setHistories] = useState<Record<number, TokenZoneData[]>>({});
    const [timeframe, setTimeframe] = useState<string>("all");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [authCode, setAuthCode] = useState("");

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;

    useEffect(() => {
        const jwtToken = getCookie("CF_Authorization");
        if (!jwtToken) {
            console.error("Auth Error: JWT token not found");
            message.error("You must be logged in.");
            setLoading(false);
            return;
        }

        fetch(`${backendUrl}/api/token-lookup`, {
            headers: {
                "Content-Type": "application/json",
                "cf-access-jwt-assertion": jwtToken,
            },
        })
            .then((resp) => {
                if (!resp.ok) throw new Error("Failed to fetch token list");
                return resp.json();
            })
            .then((tokenList: TokenOption[]) => {
                setTokens(tokenList);

                return Promise.all(
                    tokenList.map(async (tok) => {
                        let url = `${backendUrl}/api/token-history/${tok.id}`;
                        if (timeframe === "1d" || timeframe === "1w" || timeframe === "1m") {
                            url = `${backendUrl}/api/token-history/${timeframe}/${tok.id}`;
                        }
                        const res = await fetch(url, {
                            headers: {
                                "Content-Type": "application/json",
                                "cf-access-jwt-assertion": jwtToken,
                            },
                        });
                        if (!res.ok) {
                            console.error(`Error fetching history for token id=${tok.id}`);
                            return { id: tok.id, data: [] };
                        }
                        const data: TokenZoneData[] = await res.json();
                        return { id: tok.id, data };
                    })
                );
            })
            .then((results) => {
                const dict: Record<number, TokenZoneData[]> = {};
                for (const item of results) {
                    dict[item.id] = item.data;
                }
                setHistories(dict);
            })
            .catch((err) => {
                console.error("Error fetching tokens or histories:", err);
                message.error("Could not load tokens or their histories. Please try again.");
            })
            .finally(() => setLoading(false));
    }, [backendUrl, timeframe]);

    if (loading) {
        return <p>Loading token zones...</p>;
    }

    if (!tokens.length) {
        return <p>No tokens found.</p>;
    }

    const showModal = () => {
        setAuthCode("");
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsModalOpen(false);
        if (!authCode.trim()) {
            message.error("Please enter an auth code.");
            return;
        }
        try {
            const jwtToken = getCookie("CF_Authorization");
            if (!jwtToken) {
                message.error("You must be logged in.");
                return;
            }
            const res = await fetch(`${backendUrl}/api/resync-quadrants/${authCode}`, {
                headers: {
                    "Content-Type": "application/json",
                    "cf-access-jwt-assertion": jwtToken,
                },
            });
            if (!res.ok) {
                throw new Error("Resync call failed");
            }
            message.success("Resync triggered successfully.");
        } catch (err) {
            console.error("Resync error:", err);
            message.error("Resync failed. Check console/logs for details.");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div style={{ padding: 16 }}>
            <h1>Token Zones</h1>

            <div style={{ marginBottom: 16 }}>
                <Select
                    value={timeframe}
                    onChange={(value) => setTimeframe(value)}
                    style={{ width: 200 }}
                >
                    <Option value="all">All Time</Option>
                    <Option value="1d">One Day</Option>
                    <Option value="1w">One Week</Option>
                    <Option value="1m">One Month</Option>
                </Select>
            </div>

            {tokens.map((token) => {
                const zoneData = histories[token.id] || [];
                const latest = zoneData.length ? zoneData[zoneData.length - 1] : null;

                return (
                    <Card
                        key={token.id}
                        style={{ marginBottom: 16 }}
                        title={`Token: ${token.name} (ID: ${token.id})`}
                    >
                        {latest ? (
                            <div style={{ marginBottom: 8 }}>
                                <p>
                                    <strong>Current:</strong> {formatNumber(latest.current_value)}
                                </p>
                                <p>
                                    <strong>Floor:</strong> {formatNumber(latest.floor)}
                                </p>
                                <p>
                                    <strong>Low:</strong> {formatNumber(latest.low)}
                                </p>
                                <p>
                                    <strong>Med-Low:</strong> {formatNumber(latest.med_low)}
                                </p>
                                <p>
                                    <strong>Med-High:</strong> {formatNumber(latest.med_high)}
                                </p>
                                <p>
                                    <strong>High:</strong> {formatNumber(latest.high)}
                                </p>
                                <p>
                                    <strong>Updated:</strong>{" "}
                                    {new Date(latest.updated_date).toLocaleString()}
                                </p>
                            </div>
                        ) : (
                            <p>No zone data available for this timeframe.</p>
                        )}
                        {renderZonesChart(zoneData, token.name)}
                    </Card>
                );
            })}

            <div style={{ textAlign: "center", marginTop: 24 }}>
                <Button type="primary" onClick={showModal}>
                    Resync Quadrants
                </Button>
            </div>

            <Modal
                title="Enter Auth Code"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Input
                    placeholder="Auth code..."
                    value={authCode}
                    onChange={(e) => setAuthCode(e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default TokenZones;

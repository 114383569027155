// src/TradingPlanPerformance.tsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // We'll read :planId from the route
import { message, Card } from "antd";
import { getCookie } from "./utils";
// Recharts
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip as RechartTooltip,
    Legend,
    LineChart,
    Line,
} from "recharts";

/**
 * The interface returned by `/api/past-positions/:planId` for closed positions.
 */
interface ClosedTradingPosition {
    id: number;
    sym_id: number;
    quantity: number;
    enter_datetime: string;
    enter_token_value: number;
    enter_fee: number;
    enter_total_USDC: number;
    exit_percent: number;
    exit_stop_loss: number;
    exit_datetime: string;
    exit_token_value: number;
    exit_fee: number;
    exit_total_USDC: number;
    position_gain: number;
    position_percentage: number;
    position_apr: number;
    exit_token_quantity: number;
    time_horizon: string;
    token_name: string;
    current_value: number;
    floor: number;
    low: number;
    med_low: number;
    med_high: number;
    high: number;
    updated_date: string;
}

/**
 * Helper for numeric formatting with your usual rules.
 */
function formatNumber(value: number): string {
    const absVal = Math.abs(value);
    if (absVal >= 0.01) {
        return value.toFixed(2);
    } else if (absVal >= 0.0001) {
        return value.toFixed(4);
    } else {
        return value.toFixed(6);
    }
}

/**
 * The main page for viewing a Trading Plan’s closed-out performance
 */
const TradingPlanPerformance: React.FC = () => {
    // Read :planId from route
    const { planId } = useParams();
    // Store our array of closed positions
    const [positions, setPositions] = useState<ClosedTradingPosition[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;

    useEffect(() => {
        if (!planId) {
            setLoading(false);
            return;
        }

        const jwtToken = getCookie("CF_Authorization");
        if (!jwtToken) {
            message.error("You must be logged in.");
            setLoading(false);
            return;
        }

        // Fetch closed positions for this trading plan
        fetch(`${backendUrl}/api/past-positions/${planId}`, {
            headers: {
                "Content-Type": "application/json",
                "cf-access-jwt-assertion": jwtToken,
            },
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error("Failed to fetch closed positions");
                }
                return resp.json();
            })
            .then((data: ClosedTradingPosition[]) => {
                setPositions(data);
            })
            .catch((err) => {
                console.error("Error fetching closed positions:", err);
                message.error("Could not load closed positions. Please try again.");
            })
            .finally(() => setLoading(false));
    }, [planId, backendUrl]);

    if (loading) {
        return <p>Loading trading plan performance...</p>;
    }

    if (!positions.length) {
        return (
            <div style={{ padding: "16px" }}>
                <h1>Trading Plan Performance</h1>
                <p>No closed positions found for plan {planId}.</p>
            </div>
        );
    }

    // For the time horizon, we assume all positions in this plan share it
    // We'll just take the first item
    const timeHorizon = positions[0]?.time_horizon || "(Unknown)";

    // We want to visualize total USDC gain, total percentage gain, etc.
    // Let's do a quick aggregate
    const totalGainUSDC = positions.reduce((acc, p) => acc + p.position_gain, 0);
    // Weighted average? Or sum of position_percentage might not be correct
    // because each position might have different sizes. For a simple approach,
    // let's just do an average
    const avgPercentGain = positions.reduce((acc, p) => acc + p.position_percentage, 0) / positions.length;
    const avgAPR = positions.reduce((acc, p) => acc + p.position_apr, 0) / positions.length;

    // 1) Gains over time
    // We'll do a bar chart keyed by exit_datetime => p.position_gain
    // Sort by exit_datetime so the chart is in chronological order
    const sortedByExit = [...positions].sort(
        (a, b) => new Date(a.exit_datetime).getTime() - new Date(b.exit_datetime).getTime()
    );
    const gainsOverTimeData = sortedByExit.map((p) => ({
        exit_date: p.exit_datetime,
        gain: p.position_gain,
    }));

    // 2) Cumulative percentage chart:
    // We'll do a line chart that sums up the position_percentage over time.
    // Actually, "summing" percentages might not be valid. Another approach:
    // - We can store a "cumulative USDC" approach, then compute overall gain% from that.
    // For simplicity, let's just do a naive "cumulative sum of position_gain" approach,
    // then compute a final total over the plan's initial invests. But we don't have
    // the plan invests sum. We'll do a naive approach: line chart that accumulates position_gain.
    let runningTotal = 0;
    const cumulativeData = sortedByExit.map((p) => {
        runningTotal += p.position_gain;
        return {
            exit_date: p.exit_datetime,
            cumulative_gain: runningTotal,
        };
    });

    // 3) Gains by token:
    // We'll group closed positions by p.token_name
    // Then sum up the p.position_gain
    const tokenMap: Record<string, number> = {};
    positions.forEach((p) => {
        tokenMap[p.token_name] = (tokenMap[p.token_name] || 0) + p.position_gain;
    });
    // Convert to array for Recharts
    const gainsByTokenData = Object.keys(tokenMap).map((tokenName) => ({
        tokenName,
        gain: tokenMap[tokenName],
    }));

    return (
        <div style={{ padding: 16 }}>
            <h1>Trading Plan Performance (Plan ID: {planId})</h1>

            {/* Show the time horizon at top */}
            <p style={{ color: "#ccc" }}>
                <strong>Time Horizon:</strong> {timeHorizon}
            </p>

            {/* Summary card of overall stats */}
            <Card style={{ marginBottom: 16, backgroundColor: "#222", color: "#fff" }}>
                <p>
                    <strong>Total USDC Gain:</strong>{" "}
                    {totalGainUSDC >= 0 ? "+" : ""}
                    {formatNumber(totalGainUSDC)}
                </p>
                <p>
                    <strong>Avg Percentage Gain:</strong>{" "}
                    {avgPercentGain >= 0 ? "+" : ""}
                    {formatNumber(avgPercentGain)}%
                </p>
                <p>
                    <strong>Avg APR:</strong> {formatNumber(avgAPR)}%
                </p>
            </Card>

            {/* 1) Gains Over Time (Bar Chart) */}
            <div
                style={{
                    width: "100%",
                    height: 400,
                    marginTop: 16,
                    backgroundColor: "#222",
                    padding: "16px",
                    borderRadius: "8px",
                }}
            >
                <ResponsiveContainer>
                    <BarChart data={gainsOverTimeData}>
                        <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                        <XAxis
                            dataKey="exit_date"
                            stroke="#ccc"
                            tickFormatter={(val: string) => {
                                const d = new Date(val);
                                return d.toLocaleDateString();
                            }}
                        />
                        <YAxis stroke="#ccc" />
                        <RechartTooltip
                            contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                            labelStyle={{ color: "#fff" }}
                            itemStyle={{ color: "#fff" }}
                            formatter={(value: number) => `${formatNumber(value)}`}
                        />
                        <Legend wrapperStyle={{ color: "#fff" }} />
                        <Bar dataKey="gain" fill="#8884d8" name="Position Gain (USDC)" />
                    </BarChart>
                </ResponsiveContainer>
            </div>

            {/* 2) Cumulative Gains (Line Chart) */}
            <div
                style={{
                    width: "100%",
                    height: 400,
                    marginTop: 16,
                    backgroundColor: "#222",
                    padding: "16px",
                    borderRadius: "8px",
                }}
            >
                <ResponsiveContainer>
                    <LineChart data={cumulativeData}>
                        <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                        <XAxis
                            dataKey="exit_date"
                            stroke="#ccc"
                            tickFormatter={(val: string) => {
                                const d = new Date(val);
                                return d.toLocaleDateString();
                            }}
                        />
                        <YAxis stroke="#ccc" />
                        <RechartTooltip
                            contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                            labelStyle={{ color: "#fff" }}
                            itemStyle={{ color: "#fff" }}
                            formatter={(value: number) => `${formatNumber(value)} USDC`}
                        />
                        <Legend wrapperStyle={{ color: "#fff" }} />
                        <Line
                            type="monotone"
                            dataKey="cumulative_gain"
                            stroke="#4fd156"
                            strokeWidth={2}
                            dot={false}
                            name="Cumulative Gain (USDC)"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            {/* 3) Gains by Token (Bar Chart) */}
            <div
                style={{
                    width: "100%",
                    height: 400,
                    marginTop: 16,
                    backgroundColor: "#222",
                    padding: "16px",
                    borderRadius: "8px",
                }}
            >
                <ResponsiveContainer>
                    <BarChart data={gainsByTokenData}>
                        <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                        <XAxis dataKey="tokenName" stroke="#ccc" />
                        <YAxis stroke="#ccc" />
                        <RechartTooltip
                            contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                            labelStyle={{ color: "#fff" }}
                            itemStyle={{ color: "#fff" }}
                            formatter={(value: number) => `${formatNumber(value)} USDC`}
                        />
                        <Legend wrapperStyle={{ color: "#fff" }} />
                        <Bar dataKey="gain" fill="#ff7f50" name="Token Gain (USDC)" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default TradingPlanPerformance;

// src/TokenZones.tsx

import React, { useEffect, useState } from "react";
import { message, Card, Select, Button, Modal, Input, Checkbox } from "antd";
import { getCookie } from "./utils";
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip as RechartTooltip,
    Legend,
} from "recharts";

const { Option } = Select;

interface TokenOption {
    id: number;
    name: string;
    mint: string;
}

interface TokenZoneData {
    id: number;
    token_name: string;
    current_value: number;
    floor: number;
    low: number;
    med_low: number;
    med_high: number;
    high: number;
    updated_date: string;
}

// Your Bollinger interface
interface BollingerData {
    sym_id: number;
    token_name: string;
    updated_date: string;
    bol_4h_sma?: number;
    bol_4h_upper?: number;
    bol_4h_lower?: number;
    bol_1d_sma?: number;
    bol_1d_upper?: number;
    bol_1d_lower?: number;
    bol_1w_sma?: number;
    bol_1w_upper?: number;
    bol_1w_lower?: number;
    bol_20d_sma?: number;
    bol_20d_upper?: number;
    bol_20d_lower?: number;
    bol_1m_sma?: number;
    bol_1m_upper?: number;
    bol_1m_lower?: number;
}

function formatNumber(value: number): string {
    const absVal = Math.abs(value);
    if (absVal >= 0.01) {
        return value.toFixed(2);
    } else if (absVal >= 0.0001) {
        return value.toFixed(4);
    } else {
        return value.toFixed(6);
    }
}

/**
 * Merge zoneData and bollingerData by matching updated_date.
 * Returns a combined array for Recharts, including:
 * - Zone fields: current, floor, low, med_low, ...
 * - Bollinger fields: b4h_sma, b4h_upper, b4h_lower, etc.
 */
function combineChartData(zoneData: TokenZoneData[], bollData: BollingerData[]) {
    // Build a map from updated_date -> BollingerData
    const bMap = new Map<string, BollingerData>();
    for (const b of bollData) {
        bMap.set(b.updated_date, b);
    }

    // Merge each zoneData row with any matching Bollinger row
    return zoneData.map((z) => {
        const b = bMap.get(z.updated_date);
        return {
            time: z.updated_date,

            // Zone lines
            current: z.current_value,
            floor: z.floor,
            low: z.low,
            med_low: z.med_low,
            med_high: z.med_high,
            high: z.high,

            // Bollinger lines
            b4h_sma: b?.bol_4h_sma,
            b4h_upper: b?.bol_4h_upper,
            b4h_lower: b?.bol_4h_lower,
            b1d_sma: b?.bol_1d_sma,
            b1d_upper: b?.bol_1d_upper,
            b1d_lower: b?.bol_1d_lower,
            b1w_sma: b?.bol_1w_sma,
            b1w_upper: b?.bol_1w_upper,
            b1w_lower: b?.bol_1w_lower,
            b20d_sma: b?.bol_20d_sma,
            b20d_upper: b?.bol_20d_upper,
            b20d_lower: b?.bol_20d_lower,
            b1m_sma: b?.bol_1m_sma,
            b1m_upper: b?.bol_1m_upper,
            b1m_lower: b?.bol_1m_lower,
        };
    });
}

/**
 * Renders the combined Zones + Bollinger lines in one chart.
 */
function renderZonesChart(
    zoneData: TokenZoneData[],
    bollData: BollingerData[],
    tokenName: string,
    show4h: boolean,
    show1d: boolean,
    show1w: boolean,
    show20d: boolean,
    show1m: boolean
) {
    if (!zoneData.length) {
        return <p>No history available for {tokenName}.</p>;
    }

    // Combine zone + Boll data into a single array
    const chartData = combineChartData(zoneData, bollData);

    return (
        <div
            style={{
                width: "100%",
                height: 600,
                marginTop: 16,
                backgroundColor: "#222",
                padding: "16px",
                borderRadius: "8px",
            }}
        >
            <ResponsiveContainer>
                <LineChart data={chartData} margin={{ top: 40, right: 40, bottom: 40, left: 20 }}>
                    <CartesianGrid stroke="#444" strokeDasharray="3 3" />
                    <XAxis
                        dataKey="time"
                        stroke="#ccc"
                        tickFormatter={(val: string) => {
                            const d = new Date(val);
                            return d.toLocaleDateString();
                        }}
                    />
                    <YAxis stroke="#ccc" />
                    <RechartTooltip
                        contentStyle={{ backgroundColor: "#333", borderColor: "#666" }}
                        labelStyle={{ color: "#fff" }}
                        itemStyle={{ color: "#fff" }}
                    />
                    <Legend wrapperStyle={{ color: "#fff" }} />

                    {/* Existing zone lines */}
                    <Line
                        type="monotone"
                        dataKey="current"
                        stroke="#8884d8"
                        strokeWidth={2}
                        dot={false}
                        name="Current"
                    />
                    <Line
                        type="monotone"
                        dataKey="floor"
                        stroke="#999"
                        strokeDasharray="3 3"
                        dot={false}
                        name="Floor"
                    />
                    <Line
                        type="monotone"
                        dataKey="low"
                        stroke="#4fd156"
                        dot={false}
                        name="Low"
                    />
                    <Line
                        type="monotone"
                        dataKey="med_low"
                        stroke="#d1cd4f"
                        dot={false}
                        name="Med-Low"
                    />
                    <Line
                        type="monotone"
                        dataKey="med_high"
                        stroke="#d1974f"
                        dot={false}
                        name="Med-High"
                    />
                    <Line
                        type="monotone"
                        dataKey="high"
                        stroke="#d14f4f"
                        dot={false}
                        name="High"
                    />

                    {/* For each Bollinger window, we show 3 lines: Upper, SMA, Lower, if checked. */}
                    {show4h && (
                        <>
                            <Line
                                type="monotone"
                                dataKey="b4h_upper"
                                stroke="#00FFFF"
                                dot={false}
                                name="Boll 4h Upper"
                            />
                            <Line
                                type="monotone"
                                dataKey="b4h_sma"
                                stroke="#00FFFF"
                                strokeDasharray="3 3"
                                dot={false}
                                name="Boll 4h SMA"
                            />
                            <Line
                                type="monotone"
                                dataKey="b4h_lower"
                                stroke="#00FFFF"
                                strokeDasharray="6 3"
                                dot={false}
                                name="Boll 4h Lower"
                            />
                        </>
                    )}
                    {show1d && (
                        <>
                            <Line
                                type="monotone"
                                dataKey="b1d_upper"
                                stroke="#ff00ff"
                                dot={false}
                                name="Boll 1d Upper"
                            />
                            <Line
                                type="monotone"
                                dataKey="b1d_sma"
                                stroke="#ff00ff"
                                strokeDasharray="3 3"
                                dot={false}
                                name="Boll 1d SMA"
                            />
                            <Line
                                type="monotone"
                                dataKey="b1d_lower"
                                stroke="#ff00ff"
                                strokeDasharray="6 3"
                                dot={false}
                                name="Boll 1d Lower"
                            />
                        </>
                    )}
                    {show1w && (
                        <>
                            <Line
                                type="monotone"
                                dataKey="b1w_upper"
                                stroke="#FFD700"
                                dot={false}
                                name="Boll 1w Upper"
                            />
                            <Line
                                type="monotone"
                                dataKey="b1w_sma"
                                stroke="#FFD700"
                                strokeDasharray="3 3"
                                dot={false}
                                name="Boll 1w SMA"
                            />
                            <Line
                                type="monotone"
                                dataKey="b1w_lower"
                                stroke="#FFD700"
                                strokeDasharray="6 3"
                                dot={false}
                                name="Boll 1w Lower"
                            />
                        </>
                    )}
                    {show20d && (
                        <>
                            <Line
                                type="monotone"
                                dataKey="b20d_upper"
                                stroke="#FF8C00"
                                dot={false}
                                name="Boll 20d Upper"
                            />
                            <Line
                                type="monotone"
                                dataKey="b20d_sma"
                                stroke="#FF8C00"
                                strokeDasharray="3 3"
                                dot={false}
                                name="Boll 20d SMA"
                            />
                            <Line
                                type="monotone"
                                dataKey="b20d_lower"
                                stroke="#FF8C00"
                                strokeDasharray="6 3"
                                dot={false}
                                name="Boll 20d Lower"
                            />
                        </>
                    )}
                    {show1m && (
                        <>
                            <Line
                                type="monotone"
                                dataKey="b1m_upper"
                                stroke="#7FFF00"
                                dot={false}
                                name="Boll 1m Upper"
                            />
                            <Line
                                type="monotone"
                                dataKey="b1m_sma"
                                stroke="#7FFF00"
                                strokeDasharray="3 3"
                                dot={false}
                                name="Boll 1m SMA"
                            />
                            <Line
                                type="monotone"
                                dataKey="b1m_lower"
                                stroke="#7FFF00"
                                strokeDasharray="6 3"
                                dot={false}
                                name="Boll 1m Lower"
                            />
                        </>
                    )}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

const TokenZones: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [tokens, setTokens] = useState<TokenOption[]>([]);
    const [histories, setHistories] = useState<Record<number, TokenZoneData[]>>({});
    // Store Bollinger data for each token
    const [bollingers, setBollingers] = useState<Record<number, BollingerData[]>>({});

    const [timeframe, setTimeframe] = useState<string>("all");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [authCode, setAuthCode] = useState("");

    // Checkboxes for each Bollinger window (default off).
    const [show4h, setShow4h] = useState(false);
    const [show1d, setShow1d] = useState(false);
    const [show1w, setShow1w] = useState(false);
    const [show20d, setShow20d] = useState(false);
    const [show1m, setShow1m] = useState(false);

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;

    useEffect(() => {
        const jwtToken = getCookie("CF_Authorization");
        if (!jwtToken) {
            console.error("Auth Error: JWT token not found");
            message.error("You must be logged in.");
            setLoading(false);
            return;
        }

        // 1) Fetch the token list
        fetch(`${backendUrl}/api/token-lookup`, {
            headers: {
                "Content-Type": "application/json",
                "cf-access-jwt-assertion": jwtToken,
            },
        })
            .then((resp) => {
                if (!resp.ok) throw new Error("Failed to fetch token list");
                return resp.json();
            })
            .then((tokenList: TokenOption[]) => {
                setTokens(tokenList);

                // For each token, fetch the zone history and Bollinger data in parallel
                return Promise.all(
                    tokenList.map(async (tok) => {
                        // Build the zone-history URL
                        let historyUrl = `${backendUrl}/api/token-history/${tok.id}`;
                        if (["1d", "1w", "1m"].includes(timeframe)) {
                            historyUrl = `${backendUrl}/api/token-history/${timeframe}/${tok.id}`;
                        }

                        // Build the bollinger URL with matching timeframe
                        let bollUrl = `${backendUrl}/api/token-bollinger/token-history/${tok.id}`;
                        if (["1d", "1w", "1m"].includes(timeframe)) {
                            bollUrl = `${backendUrl}/api/token-bollinger/${timeframe}/${tok.id}`;
                        }

                        // Fetch zone history
                        const histRes = await fetch(historyUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                "cf-access-jwt-assertion": jwtToken,
                            },
                        });
                        let histData: TokenZoneData[] = [];
                        if (histRes.ok) {
                            histData = await histRes.json();
                        } else {
                            console.error(`Error fetching history for token id=${tok.id}`);
                        }

                        // Fetch Bollinger data
                        const bollRes = await fetch(bollUrl, {
                            headers: {
                                "Content-Type": "application/json",
                                "cf-access-jwt-assertion": jwtToken,
                            },
                        });
                        let bollData: BollingerData[] = [];
                        if (bollRes.ok) {
                            bollData = await bollRes.json();
                        } else {
                            console.error(`Error fetching bollingers for token id=${tok.id}`);
                        }

                        return { id: tok.id, zoneData: histData, bollData };
                    })
                );
            })
            .then((results) => {
                // 2) Update state for zone histories and Bollingers
                const zoneDict: Record<number, TokenZoneData[]> = {};
                const bollDict: Record<number, BollingerData[]> = {};

                for (const item of results) {
                    zoneDict[item.id] = item.zoneData;
                    bollDict[item.id] = item.bollData;
                }

                setHistories(zoneDict);
                setBollingers(bollDict);
            })
            .catch((err) => {
                console.error("Error fetching tokens or histories:", err);
                message.error("Could not load tokens or their histories. Please try again.");
            })
            .finally(() => setLoading(false));
    }, [backendUrl, timeframe]);

    if (loading) {
        return <p>Loading token zones...</p>;
    }

    if (!tokens.length) {
        return <p>No tokens found.</p>;
    }

    const showModal = () => {
        setAuthCode("");
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsModalOpen(false);
        if (!authCode.trim()) {
            message.error("Please enter an auth code.");
            return;
        }
        try {
            const jwtToken = getCookie("CF_Authorization");
            if (!jwtToken) {
                message.error("You must be logged in.");
                return;
            }
            const res = await fetch(`${backendUrl}/api/resync-quadrants/${authCode}`, {
                headers: {
                    "Content-Type": "application/json",
                    "cf-access-jwt-assertion": jwtToken,
                },
            });
            if (!res.ok) {
                throw new Error("Resync call failed");
            }
            message.success("Resync triggered successfully.");
        } catch (err) {
            console.error("Resync error:", err);
            message.error("Resync failed. Check console/logs for details.");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div style={{ padding: 16 }}>
            <h1>Token Zones</h1>

            <div style={{ marginBottom: 16 }}>
                <Select
                    value={timeframe}
                    onChange={(value) => setTimeframe(value)}
                    style={{ width: 200 }}
                >
                    <Option value="all">All Time</Option>
                    <Option value="1d">One Day</Option>
                    <Option value="1w">One Week</Option>
                    <Option value="1m">One Month</Option>
                </Select>
            </div>

            {/* Bollinger time_window checkboxes (default off) */}
            <div style={{ marginBottom: 16 }}>
                <Checkbox checked={show4h} onChange={(e) => setShow4h(e.target.checked)}>
                    4h Bollingers
                </Checkbox>
                <Checkbox checked={show1d} onChange={(e) => setShow1d(e.target.checked)}>
                    1d Bollingers
                </Checkbox>
                <Checkbox checked={show1w} onChange={(e) => setShow1w(e.target.checked)}>
                    1w Bollingers
                </Checkbox>
                <Checkbox checked={show20d} onChange={(e) => setShow20d(e.target.checked)}>
                    20d Bollingers
                </Checkbox>
                <Checkbox checked={show1m} onChange={(e) => setShow1m(e.target.checked)}>
                    1m Bollingers
                </Checkbox>
            </div>

            {tokens.map((token) => {
                const zoneData = histories[token.id] || [];
                const bollData = bollingers[token.id] || [];
                const latest = zoneData.length ? zoneData[zoneData.length - 1] : null;

                return (
                    <Card
                        key={token.id}
                        style={{ marginBottom: 16 }}
                        title={`Token: ${token.name} (ID: ${token.id})`}
                    >
                        {latest ? (
                            <div style={{ marginBottom: 8 }}>
                                <p>
                                    <strong>Current:</strong> {formatNumber(latest.current_value)}
                                </p>
                                <p>
                                    <strong>Floor:</strong> {formatNumber(latest.floor)}
                                </p>
                                <p>
                                    <strong>Low:</strong> {formatNumber(latest.low)}
                                </p>
                                <p>
                                    <strong>Med-Low:</strong> {formatNumber(latest.med_low)}
                                </p>
                                <p>
                                    <strong>Med-High:</strong> {formatNumber(latest.med_high)}
                                </p>
                                <p>
                                    <strong>High:</strong> {formatNumber(latest.high)}
                                </p>
                                <p>
                                    <strong>Updated:</strong>{" "}
                                    {new Date(latest.updated_date).toLocaleString()}
                                </p>
                            </div>
                        ) : (
                            <p>No zone data available for this timeframe.</p>
                        )}

                        {/* Render the combined chart */}
                        {renderZonesChart(
                            zoneData,
                            bollData,
                            token.name,
                            show4h,
                            show1d,
                            show1w,
                            show20d,
                            show1m
                        )}
                    </Card>
                );
            })}

            <div style={{ textAlign: "center", marginTop: 24 }}>
                <Button type="primary" onClick={showModal}>
                    Resync Quadrants
                </Button>
            </div>

            <Modal
                title="Enter Auth Code"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Input
                    placeholder="Auth code..."
                    value={authCode}
                    onChange={(e) => setAuthCode(e.target.value)}
                />
            </Modal>
        </div>
    );
};

export default TokenZones;

import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Select, message, Modal, List } from "antd";
import { getCookie } from "./utils";

const { Option } = Select;

interface TokenOption {
    id: number;
    name: string;
    mint: string;
}

interface TradingPlan {
    id: string;
    name: string;
    investment_usdc: number;
    harvest_perc: number;
    cap_usdc: number;
    stoploss_perc: number;
    tokens: TokenOption[];
    time_horizon: string;
    open_positions: number;
}

interface TradingPlanFormProps {
    walletAddress: string | null;
    onPlanAdded: () => void;  // Called after a successful create or edit
    planToEdit?: TradingPlan; // Optional: If present, we're editing an existing plan
}

const TradingPlanForm: React.FC<TradingPlanFormProps> = ({
                                                             walletAddress,
                                                             onPlanAdded,
                                                             planToEdit
                                                         }) => {
    const [name, setName] = useState("");
    const [investment, setInvestment] = useState(0);
    const [harvest_perc, setHarvestPerc] = useState(0);
    const [cap, setCap] = useState(0);
    const [stoploss_perc, setStopLossPerc] = useState(0);
    const [timeHorizon, setTimeHorizon] = useState("");
    const [selectedTokens, setSelectedTokens] = useState<TokenOption[]>([]);

    // For the add-token modal
    const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);
    const [tokenQuery, setTokenQuery] = useState("");
    const [tokenSearchResults, setTokenSearchResults] = useState<TokenOption[]>([]);
    const [isSearching, setIsSearching] = useState(false);

    document.title =
        process.env.REACT_APP_CF_PAGES_ENV === 'production'
            ? 'DigitalFx'
            : 'DigitalFx - TEST';

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;

    // If we’re editing, pre-fill the form fields from `planToEdit`
    useEffect(() => {
        if (planToEdit) {
            setName(planToEdit.name);
            setInvestment(planToEdit.investment_usdc);
            setHarvestPerc(planToEdit.harvest_perc);
            setCap(planToEdit.cap_usdc);
            setStopLossPerc(planToEdit.stoploss_perc);
            setTimeHorizon(planToEdit.time_horizon);

            // We now know planToEdit.tokens is always an array.
            setSelectedTokens(planToEdit.tokens);
        }
    }, [planToEdit]);

    // 1) As soon as the token modal opens, fetch the full token list.
    useEffect(() => {
        if (isTokenModalOpen) {
            handleTokenSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTokenModalOpen]);

    const handleSubmit = async () => {
        if (!walletAddress) {
            message.error("Please connect your wallet first.");
            return;
        }

        if (selectedTokens.length === 0) {
            message.error("Please add at least one token.");
            return;
        }

        const jwtToken = getCookie('CF_Authorization');
        if (!jwtToken) {
            console.error('Auth Error: JWT token not found');
            return;
        }

        // Build the request body
        const planBody = {
            wallet_address: walletAddress,
            name,
            investment_usdc: investment,
            harvest_perc,
            cap_usdc: cap,
            stoploss_perc,
            tokens: selectedTokens, // array of { id, name, mint }
            time_horizon: timeHorizon,
        };

        try {
            // If we're editing an existing plan, do PUT. Otherwise, do POST.
            let url = `${backendUrl}/api/trading-plans`;
            let method = "POST";

            if (planToEdit) {
                url = `${backendUrl}/api/trading-plans/${planToEdit.id}`;
                method = "PUT";
            }

            const response = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                    "cf-access-jwt-assertion": jwtToken,
                },
                body: JSON.stringify(planBody),
            });

            if (!response.ok) {
                throw new Error(
                    planToEdit ? "Failed to update trading plan" : "Failed to save trading plan"
                );
            }

            const data = await response.json();
            console.log("Trading plan response:", data);

            if (planToEdit) {
                message.success("Trading plan updated successfully!");
            } else {
                message.success("Trading plan saved successfully!");
            }

            // Trigger the parent callback to refresh
            onPlanAdded();
        } catch (error) {
            console.error("Error saving trading plan:", error);
            message.error(
                planToEdit
                    ? "Failed to update trading plan. Please try again."
                    : "Failed to save trading plan. Please try again."
            );
        }
    };

    const openTokenModal = () => {
        setIsTokenModalOpen(true);
        setTokenQuery("");
        setTokenSearchResults([]);
    };

    const closeTokenModal = () => {
        setIsTokenModalOpen(false);
    };

    // 2) Fetch the full token list from the backend
    //    We'll store it in `tokenSearchResults` initially,
    //    then filter it locally by `tokenQuery`.
    const handleTokenSearch = async () => {
        const jwtToken = getCookie('CF_Authorization');
        if (!jwtToken) {
            console.error('Auth Error: JWT token not found');
            return;
        }

        setIsSearching(true);
        try {
            const res = await fetch(`${backendUrl}/api/token-lookup`, {
                headers: {
                    "Content-Type": "application/json",
                    "cf-access-jwt-assertion": jwtToken,
                },
            });
            if (!res.ok) {
                throw new Error("Failed to search tokens");
            }

            const data = await res.json();
            // data should be an array of { id, name, mint }
            setTokenSearchResults(data);
        } catch (err) {
            console.error("Error searching tokens:", err);
            message.error("Failed to search tokens. Please try again.");
        } finally {
            setIsSearching(false);
        }
    };

    // Compute the filtered list as user types in tokenQuery
    const filteredTokens = tokenSearchResults.filter((item) =>
        item.name.toLowerCase().includes(tokenQuery.toLowerCase())
    );

    const handleSelectToken = (token: TokenOption) => {
        // Add token to selectedTokens if not already present
        if (!selectedTokens.find((t) => t.id === token.id)) {
            setSelectedTokens([...selectedTokens, token]);
        } else {
            message.info("Token already selected.");
        }
        closeTokenModal();
    };

    const removeSelectedToken = (tokenId: number) => {
        setSelectedTokens(selectedTokens.filter((t) => t.id !== tokenId));
    };

    return (
        <>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Plan Name" required>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter a name for this trading plan"
                    />
                </Form.Item>

                <Form.Item label="Investment (USDC)" required>
                    <InputNumber
                        value={investment}
                        onChange={(value) => setInvestment(value || 0)}
                        min={0}
                        placeholder="Enter investment amount in USDC"
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Form.Item label="Cap (USDC)" required>
                    <InputNumber
                        value={cap}
                        onChange={(value) => setCap(value || 0)}
                        min={0}
                        placeholder="Enter cap amount in USDC"
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Form.Item label="Tokens" required>
                    {/* Display currently selected tokens */}
                    {selectedTokens.length > 0 ? (
                        <List
                            bordered
                            dataSource={selectedTokens}
                            renderItem={(token) => (
                                <List.Item
                                    actions={[
                                        <Button
                                            type="link"
                                            onClick={() => removeSelectedToken(token.id)}
                                        >
                                            Remove
                                        </Button>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={`${token.name} (ID: ${token.id})`}
                                        description={token.mint}
                                    />
                                </List.Item>
                            )}
                        />
                    ) : (
                        <p>No tokens selected yet.</p>
                    )}
                    <Button onClick={openTokenModal} style={{ marginTop: "8px" }}>
                        Add Token
                    </Button>
                </Form.Item>

                <Form.Item label="Time Horizon" required>
                    <Select
                        onChange={(value) => setTimeHorizon(value)}
                        placeholder="Select time horizon"
                        value={timeHorizon}
                    >
                        <Option value="1h">One Hour</Option>
                        <Option value="1d">One Day</Option>
                        <Option value="1w">One Week</Option>
                        <Option value="1m">One Month</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Harvest Percentage" required>
                    <InputNumber
                        value={harvest_perc}
                        onChange={(value) => setHarvestPerc(value || 0)}
                        min={0}
                        placeholder="Enter desired Harvest Percentage"
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Form.Item label="Stop Loss Percentage" required>
                    <InputNumber
                        value={stoploss_perc}
                        onChange={(value) => setStopLossPerc(value || 0)}
                        min={0}
                        placeholder="Enter desired Stop Loss Percentage"
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    {planToEdit ? "Update" : "Submit"}
                </Button>
            </Form>

            {/* Token Search Modal */}
            <Modal
                title="Add a Token"
                visible={isTokenModalOpen}
                onCancel={closeTokenModal}
                footer={null}
            >
                {/* As user types, filter results in real-time */}
                <Input
                    placeholder="Type token name"
                    value={tokenQuery}
                    onChange={(e) => setTokenQuery(e.target.value)}
                    onPressEnter={handleTokenSearch}
                />
                <Button
                    style={{ marginTop: "8px" }}
                    type="primary"
                    onClick={handleTokenSearch}
                    loading={isSearching}
                >
                    Search
                </Button>
                <List
                    style={{ marginTop: "16px" }}
                    bordered
                    dataSource={filteredTokens}
                    renderItem={(item) => (
                        <List.Item
                            onClick={() => handleSelectToken(item)}
                            style={{ cursor: "pointer" }}
                        >
                            <List.Item.Meta
                                title={`${item.name} (ID: ${item.id})`}
                                description={item.mint}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    );
};

export default TradingPlanForm;

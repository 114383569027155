import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Modal, List, message } from "antd";
import TradingPlanForm from "./TradingPlanForm";
import { getCookie } from "./utils"; // Import getCookie function

// New interface for tokens
interface TokenOption {
    id: number;
    name: string;
    mint: string;
}

interface TradingPlan {
    id: string;
    name: string;
    investment_usdc: number;
    harvest_perc: number;
    cap_usdc: number;
    stoploss_perc: number;
    tokens: TokenOption[];
    time_horizon: string;
    open_positions: number; // newly added property
}

interface TradingPlansDashboardProps {
    walletAddress: string | null; // Wallet address passed from WalletConnect
}

const TradingPlansDashboard: React.FC<TradingPlansDashboardProps> = ({ walletAddress }) => {
    const [tradingPlans, setTradingPlans] = useState<TradingPlan[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // For editing an existing plan
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [planBeingEdited, setPlanBeingEdited] = useState<TradingPlan | null>(null);

    document.title =
        process.env.REACT_APP_CF_PAGES_ENV === 'production'
            ? 'DigitalFx'
            : 'DigitalFx - TEST';
    console.log("ENV is ", process.env.REACT_APP_CF_PAGES_ENV);

    const backendUrl = process.env.REACT_APP_TP_BACK_URL;

    // Wrap fetchTradingPlans in useCallback
    const fetchTradingPlans = useCallback(() => {
        if (!walletAddress) return;

        // Get the JWT token from the cookie
        const jwtToken = getCookie('CF_Authorization');
        if (!jwtToken) {
            console.error('Auth Error: JWT token not found');
            return;
        }

        fetch(`${backendUrl}/api/trading-plans`, {
            headers: {
                'Content-Type': 'application/json',
                'cf-access-jwt-assertion': jwtToken, // Include JWT token in header
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch trading plans");
                }
                return response.json();
            })
            .then((data) => {
                console.log("Fetched trading plans:", data);
                setTradingPlans(data);
            })
            .catch((error) => {
                console.error("Error fetching trading plans:", error);
                message.error("Could not load trading plans. Please try again.");
            });
    }, [walletAddress, backendUrl]);

    useEffect(() => {
        fetchTradingPlans();
    }, [fetchTradingPlans]);

    // Called after a successful creation or edit
    const addTradingPlan = () => {
        setIsModalOpen(false);
        fetchTradingPlans(); // Refresh the plans after adding/editing
    };

    // For opening the Add Plan modal
    const openAddModal = () => {
        setIsModalOpen(true);
    };

    // For opening the Edit Plan modal
    const openEditModal = (plan: TradingPlan) => {
        setPlanBeingEdited(plan);
        setIsEditModalOpen(true);
    };

    // Called after a successful edit to refresh the data and close the edit modal
    const handleEditSuccess = () => {
        setIsEditModalOpen(false);
        fetchTradingPlans();
    };

    const archiveTradingPlan = (id: string) => {
        // Get the JWT token from the cookie
        const jwtToken = getCookie('CF_Authorization');
        if (!jwtToken) {
            console.error('Auth Error: JWT token not found');
            return;
        }

        fetch(`${backendUrl}/api/trading-plans/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'cf-access-jwt-assertion': jwtToken, // Include JWT token in header
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((data) => {
                        console.error("Error from server:", data.error);
                        if (data.error === "Trading Plan has OPEN Positions!") {
                            throw data.error;
                        } else {
                            throw new Error("Failed to archive trading plan");
                        }
                    });
                }
                message.success("Trading plan archived successfully!");
                setTradingPlans((prevPlans) =>
                    prevPlans.filter((plan) => plan.id !== id)
                );
            })
            .catch((error) => {
                console.error("Error archiving trading plan:", error);
                if (error === "Trading Plan has OPEN Positions!") {
                    message.error("Could not archive trading plan. Trading Plan has OPEN Positions!");
                } else {
                    message.error("Could not archive trading plan. Please try again.");
                }
            });
    };

    if (!walletAddress) {
        return <p>Trading Plans Unavailable. Please connect your wallet.</p>;
    }

    return (
        <div>
            <h1>Trading Plans</h1>
            <Button type="primary" onClick={openAddModal}>
                Add Trading Plan
            </Button>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={tradingPlans}
                renderItem={(plan) => {
                    // If there is exactly 1 open position, we hide the "Archive" button
                    // and make the plan name a hyperlink to PositionPerformance.tsx
                    const planTitle = plan.open_positions === 1
                        ? <Link to={`/position-performance/${plan.id}`}>{plan.name}</Link>
                        : plan.name;

                    return (
                        <List.Item>
                            <Card
                                title={
                                <>
                                    {planTitle}
                                    {" "}
                                    <Link to={`/trading-plan-performance/${plan.id}`}>
                                    (performance)
                                    </Link>
                                </>
                            }
                                extra={
                                    <>
                                        <Button
                                            type="link"
                                            onClick={() => openEditModal(plan)}
                                        >
                                            Edit
                                        </Button>
                                        {plan.open_positions !== 1 && (
                                            <Button
                                                type="link"
                                                danger
                                                onClick={() => archiveTradingPlan(plan.id)}
                                            >
                                                Archive
                                            </Button>
                                        )}
                                    </>
                                }
                            >
                                <p>Investment: {plan.investment_usdc} USDC</p>
                                <p>Cap: {plan.cap_usdc} USDC</p>
                                {/* Map over plan.tokens to display comma-separated token names */}
                                <p>
                                    Tokens:{" "}
                                    {plan.tokens.map(token => token.name).join(", ")}
                                </p>
                                <p>Time Horizon: {plan.time_horizon}</p>
                                <p>Harvest Percentage: {plan.harvest_perc}</p>
                                <p>Stop Loss Percentage: {plan.stoploss_perc}</p>
                            </Card>
                        </List.Item>
                    );
                }}
            />

            {/* Modal for Adding a new plan */}
            <Modal
                title="Create Trading Plan"
                visible={isModalOpen}
                footer={null}
                onCancel={() => setIsModalOpen(false)}
            >
                <TradingPlanForm
                    walletAddress={walletAddress}
                    onPlanAdded={addTradingPlan}
                />
            </Modal>

            {/* Modal for Editing an existing plan */}
            <Modal
                title="Edit Trading Plan"
                visible={isEditModalOpen}
                footer={null}
                onCancel={() => setIsEditModalOpen(false)}
            >
                {planBeingEdited && (
                    <TradingPlanForm
                        walletAddress={walletAddress}
                        onPlanAdded={handleEditSuccess}
                        planToEdit={planBeingEdited}
                    />
                )}
            </Modal>
        </div>
    );
};

export default TradingPlansDashboard;
